import AuthHeader from "./AuthHeader";
import SubFeatures from './Subscription/SubFeatures';
import SubFeaturesLowerLeft from './Subscription/SubFeaturesLowerLeft';
import SubFeaturesLowerRight from './Subscription/SubFeaturesLowerRight';
import SubBasicFeatures from './Subscription/SubBasicFeatures';
import SubGrowthFeatures from './Subscription/SubGrowthFeatures';
import SubUnlimitedFeatures from './Subscription/SubUnlimitedFeatures';
import SubBackground from './Subscription/SubBackground';
import moment from 'moment';
import { mapMutations } from "vuex";
import $ from 'jquery';
import { times } from "underscore";

export default {
    name: 'SubscriptionV2',
    data: () => ({
        isMobile: false,
        activePlan: 'all',
        loading: true,
        couponCode: false,
        couponExpire: {
            seconds: '00',
            minutes: '00',
            hours: '00',
        },
        interval: null,
        alert: {
            show: false,
            message: '',
            theme: '',
        },
        paymentPlatform: null,
        selectedPlan: null,
        platformCustomer: null,
        paymentPlatformInstance: null,
        oldUser: false,
        single_plan_page: false,
        blockCart: false,
        checkoutSession: null,
        messages: null,
    }),
    components: {
        AuthHeader,
        SubFeatures,
        SubFeaturesLowerLeft,
        SubFeaturesLowerRight,
        SubBasicFeatures,
        SubGrowthFeatures,
        SubUnlimitedFeatures,
        SubBackground,
    },
    mounted() {
        this.plansListingLimit();
        this.initPaymentPlatform();
        this.isMobileCheck();
        window.addEventListener('resize', this.isMobileCheck);
    },
    computed: {
        userInfo: {
            get() {
                return this.$store.state.userData;
            },
        },
    },
    methods: {

        // Payment functions
        plansListingLimit()
        {
            const urlParams = this.$route.params;
            if (urlParams && urlParams.id) {
                this.single_plan_page = urlParams.id;
            }
        },
        initPaymentPlatform() {

            this.axios.get('payments/init-platform?request_source=adilo regular signup')
            .then(response => {
                this.loading = false;

                if (response.data.restore) {

                    this.alertTrigger('success-theme', 'Restoring session');
                    const { object_id, platform_id } = response.data;
                    this.processFastSpringOrder({id: object_id}, platform_id);
                    return true;
                }
                const platform  = response.data;
                const coupon    = platform.coupon;
                const customer  = platform.customer_details;
                const oldPlan   = platform.old_plan;

                this.oldUser            = oldPlan ? true : false;
                this.paymentPlatform    = platform;
                this.platformCustomer   = customer;

                if (oldPlan && oldPlan != 'free') {
                    this.single_plan_page = oldPlan;
                    this.selectedPlan = `${oldPlan}-renewal`;
                    this.openThirdPartyCheckout(platform);
                }

                if (coupon && coupon.created_at) {
                    this.couponCode = coupon.coupon_id;

                    // this.startCouponTimer(coupon.created_at);
                }
            })
            .catch(error => {
                const details = error.response.data;
                if (details.message) {
                    this.alertTrigger('error-theme', details.message);
                }
            });
        },
        planRedirect(plan) {
            if (this.loading) return false;
            plan = this.oldUser ? `${plan}-renewal` : plan;
            this.selectedPlan = plan;
            const platform = this.paymentPlatform;

            if (platform.has_checkout) this.openThirdPartyCheckout(platform);
            else this.$router.push(`/checkout?plan=${plan}`);
        },
        openThirdPartyCheckout(platform)
        {
            const ref = this;
            switch (platform.slug) {
                case 'fast-spring':
                    addEventListener('fast-spring-cart-close', function (order) {
                        ref.processFastSpringOrder(order.detail);
                    });
                    ref.checkoutFastSpring(platform);
                break;
            }
        },

        // Fastspring functions
        checkoutFastSpring(platform)
        {
            const { customer_id } = this.platformCustomer;
            const data = {
                customer_id,
                product_id: this.selectedPlan,
                platform_id: platform.slug,
                checkout_page: 'main'
            }

            if (!data.customer_id || !data.product_id || !data.platform_id) {
                this.alertTrigger('error-theme', 'Server error, please try again');
                // window.location.reload();
                return false;
            }

            // Create session to link customer in checkout
            this.loading = true;
            this.axios.post('payments/sessions', data).then(response => {
                    this.loading = false;
                    const session = response.data.session;
                    const sessionId = session ? session.id : false;
                    const overage = session.overage;

                    this.checkoutSession = sessionId;                
                    if (overage && overage > 0) {
                        this.blockCart = true;
                        this.messages = [`You checkout will also include previous overage charges.`];
                    } else {
                        this.blockCart = false;
                    }
                    if (sessionId && !this.blockCart) fastspring.builder.checkout(sessionId);
                    else if (!sessionId && !this.blockCart) this.alertTrigger('error-theme', 'Failed to create checkout session.');

                }).catch(error => {
                    this.loading = false;
                    const details = error.response.data;
                    if (details.message) this.alertTrigger('error-theme', details.message);
                });
        },
        openCart()
        {
            const platform = this.paymentPlatform;
            this.blockCart = false;
            this.messages = null;
            if (platform) {
                const id = platform.slug;
                switch (id) {
                    case 'fast-spring':
                        const sessionId = this.checkoutSession;
                        if (sessionId) {
                            fastspring.builder.checkout(sessionId)
                        } else {
                            this.alertTrigger('error-theme', 'Checkout session expired');
                        }
                    break;
                }
            }
        },
        processFastSpringOrder(order, platform_id = null) {

            if (order && order != 'undefined' && order.id) {
                this.loading = true;
                setTimeout(() => {
                    this.axios.post('payments/subscription', {
                        object_id: order.id,
                        platform_id: platform_id ? platform_id : this.paymentPlatform.slug,
                    }).then(response => {
                        console.log(response);
                        if (response.data.user) {
                            const user = response.data.user;
                            this.setUserInfo(user);
                            // this.alertTrigger('success-theme', 'Account created, redirecting...');
                            // setTimeout(() => {
                            //     this.$router.push('/');
                            //     this.loading = false;
                            // }, 3000);
                            this.$router.push('/');
                            this.loading = false;
                        } else {
                            this.blockCart = true;
                            this.alertTrigger('error-theme', 'Failed to restore session, please contact help');
                        }
                    }).catch(error => {
                        this.loading = false;
                        console.log({error});
                        this.alertTrigger('error-theme', 'Failed to process subscription, please contact admin');
                    });
                }, 3000);
            }
        },

        // Layout function
        selectPlanBlock(section) {
            this.activePlan = section;
            setTimeout(() => { this.positionElements() }, 100);
        },
        isMobileCheck() {
            const width = window.innerWidth;
            const height = window.innerHeight;

            if (width <= 1024 || /Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(navigator.userAgent)) {
                this.isMobile = true;
                this.activePlan = 'basic';

            } else {
                this.isMobile = false;
                this.activePlan = 'all';
            }
            setTimeout(() => { this.positionElements() }, 1500);
        },
        positionElements()
        {
            const features = $('.f-container').find('.feature');
            features.each(function (index) {
                const feature = $(this);
                const position = feature.position();
                const id = feature.data('id');
                $(`.${id}-value`).css('top', position.top);
            })
            
        },
        startCouponTimer(date) {
            let expiresAt = moment.utc(date).add('30', 'minutes');
            let ref = this;

            this.interval = setInterval(() => {
                let now = moment();
                let expireDiff = expiresAt.diff(now);
                let timer = (moment.duration(expireDiff));
                let seconds = timer.seconds();
                let minutes = timer.minutes();
                let hours   = timer.hours();

                seconds = seconds.toString().length < 2 ? `0${seconds}` : seconds;
                minutes = minutes.toString().length < 2 ? `0${minutes}` : minutes;
                hours = hours.toString().length < 2 ? `0${hours}` : hours;
                if (seconds <= 0 && minutes <= 0 && hours <= 0) {
                    ref.couponExpire.seconds = '00';
                    ref.couponExpire.minutes = '00';
                    ref.couponExpire.hours   = '00';
                    clearInterval(this.interval);
                }  else {    
                    ref.couponExpire.seconds = seconds;
                    ref.couponExpire.minutes = minutes;
                    ref.couponExpire.hours   = hours;
                }
            }, 1000);

        },
        alertTrigger(theme, message = false) {
            this.alert.show = message ? true : message;
            this.alert.message = message ? message : '';
            this.alert.theme = theme;
        },
        ...mapMutations({
            setUserInfo: "setUserInfo",
        }),
    },
}