<template>
    <div class="f-container">
        <div class="feature">
            <div class="d-flex" data-id='storage-feature'>
                <img v-if='!mobile' src="../../../assets/img/subFeatures/storage.svg" alt="storage" class="feature-icon" />
                <img v-else src="../../../assets/img/subFeatures/mobile/storage.svg" alt="storage" class="feature-icon" />
                <div class="feature-title">Storge</div>
            </div>
        </div>
        <div class="feature" data-id='bandwidth-feature'>
            <div class="d-flex">
                <img v-if='!mobile' src="../../../assets/img/subFeatures/bandwidth.svg" alt="storage" class="feature-icon" />
                <img v-else src="../../../assets/img/subFeatures/mobile/bandwidth.svg" alt="storage" class="feature-icon" />
                <div class="feature-title">Bandwidth (monthly)</div>
            </div>
        </div>
        <div class="feature" data-id='stage-feature'>
            <div class="d-flex">
                <img v-if='!mobile' src="../../../assets/img/subFeatures/stage.svg" alt="storage" class="feature-icon" />
                <img v-else src="../../../assets/img/subFeatures/mobile/stage.svg" alt="storage" class="feature-icon" />
                <div class="feature-title">Stage video channel</div>
            </div>
        </div>
        <div class="feature" data-id='experimentation-feature'>
            <div class="d-flex">
                <img v-if='!mobile' src="../../../assets/img/subFeatures/experimentation.svg" alt="storage" class="feature-icon" />
                <img v-else src="../../../assets/img/subFeatures/mobile/experimentation.svg" alt="storage" class="feature-icon" />
                <div class="feature-title">Video experimentation & split testing</div>
            </div>
        </div>
        <div class="feature" data-id='snapbyte-feature'>
            <div class="d-flex">
                <img v-if='!mobile' src="../../../assets/img/subFeatures/snapbyte.svg" alt="storage" class="feature-icon" />
                <img v-else src="../../../assets/img/subFeatures/mobile/snapbyte.svg" alt="storage" class="feature-icon" />
                <div class="feature-title">Snapbyte video recorder & messaging</div>
            </div>
        </div>
        <div class="feature" data-id='captions-feature'>
            <div class="d-flex">
                <img v-if='!mobile' src="../../../assets/img/subFeatures/captions.svg" alt="storage" class="feature-icon" />
                <img v-else src="../../../assets/img/subFeatures/mobile/captions.svg" alt="storage" class="feature-icon" />
                <div class="feature-title">A.I. captions & translations (monthly)</div>
            </div>
        </div>
        <div class="feature" data-id='schedule-streaming-feature'>
            <div class="d-flex">
                <img v-if='!mobile' src="../../../assets/img/subFeatures/schedule-streaming.svg" alt="storage" class="feature-icon" />
                <img v-else src="../../../assets/img/subFeatures/mobile/schedule-streaming.svg" alt="storage" class="feature-icon" />
                <div class="feature-title">Scheduled streaming</div>
            </div>
        </div>
        <div class="feature" data-id='pixels-feature'>
            <div class="d-flex">
                <img v-if='!mobile' src="../../../assets/img/subFeatures/pixels.svg" alt="storage" class="feature-icon" />
                <img v-else src="../../../assets/img/subFeatures/mobile/pixels.svg" alt="storage" class="feature-icon" />
                <div class="feature-title">Ad pixels retargeting</div>
            </div>
        </div>
        <div class="feature" data-id='auto-tagging-feature'>
            <div class="d-flex">
                <img v-if='!mobile' src="../../../assets/img/subFeatures/leads.svg" alt="storage" class="feature-icon" />
                <img v-else src="../../../assets/img/subFeatures/mobile/leads.svg" alt="storage" class="feature-icon" />
                <div class="feature-title">Leads auto-tagging</div>
            </div>
        </div>
        <div class="feature" data-id='streaming-feature'>
            <div class="d-flex">
                <img v-if='!mobile' src="../../../assets/img/subFeatures/advanced-streaming.svg" alt="storage" class="feature-icon" />
                <img v-else src="../../../assets/img/subFeatures/mobile/advanced-streaming.svg" alt="storage" class="feature-icon" />
                <div class="feature-title">Advanced streaming technology</div>
            </div>
            <div class="feature-description">Geo-location control, resume play technology, sticky streaming and pause on scroll technology</div>
        </div>
        <div class="feature" data-id='users-feature'>
            <div class="d-flex">
                <img v-if='!mobile' src="../../../assets/img/subFeatures/sub-users.svg" alt="storage" class="feature-icon" />
                <img v-else src="../../../assets/img/subFeatures/mobile/sub-users.svg" alt="storage" class="feature-icon" />
                <div class="feature-title">Sub users & collaboration</div>
            </div>
        </div>
        <div class="feature" data-id='drm-feature'>
            <div class="d-flex">
                <img v-if='!mobile' src="../../../assets/img/subFeatures/anti-piracy.svg" alt="storage" class="feature-icon" />
                <img v-else src="../../../assets/img/subFeatures/mobile/anti-piracy.svg" alt="storage" class="feature-icon" />
                <div class="feature-title">Anti-piracy multi-DRM licenses (monthly)</div>
            </div>
        </div>
        <div class="feature" data-id='dynamic-watermarking-feature'>
            <div class="d-flex">
                <img v-if='!mobile' src="../../../assets/img/subFeatures/dynamic-watermarking.svg" alt="storage" class="feature-icon" />
                <img v-else src="../../../assets/img/subFeatures/mobile/dynamic-watermarking.svg" alt="storage" class="feature-icon" />
                <div class="feature-title">Dynamic visual watermarking (monthly)</div>
            </div>
            <div class="feature-description">Deter viewers from screen recording your premium videos with personalised real time watermarks</div>
        </div>
        <div class="feature" data-id='account-manager-feature'>
            <div class="d-flex">
                <img v-if='!mobile' src="../../../assets/img/subFeatures/account-manager.svg" alt="storage" class="feature-icon" />
                <img v-else src="../../../assets/img/subFeatures/mobile/account-manager.svg" alt="storage" class="feature-icon" />
                <div class="feature-title">Dedicated account manager</div>
            </div>
        </div>
    </div>
</template>

<style lang="less" scoped>
    @import '../../../assets/less/custom/fonts.less';
    .f-container {
        color: #223450;
        font-family: "Poppins-Regular";
        .feature {
            margin-bottom: 15px;
            img {
                align-self: flex-start;
            }
            .feature-title {
                margin-left: 7px;
                width: 180px;
                line-height: 1.5;
                font-family: "Poppins-Regular";
                font-size: 15px;
            }
            .feature-description {
                font-size: 10px;
                margin-left: 24px;
                width: 200px;
                font-family: "Poppins-Light";
                font-size: 10px;
                text-shadow: 0 0 #000000;
            }
        }
    }
</style>

<script>
export default {
    name: "SubFeatures",
    props: ['mobile']
}
</script>