<template>
	<div v-if='mobile' class="background">
		<svg xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" width="423" height="550" viewBox="0 0 423 491.891">
		  <defs>
		    <filter id="Rectangle-6" x="0" y="0" width="423" height="491.891" filterUnits="userSpaceOnUse">
		      <feOffset dy="1" input="SourceAlpha"/>
		      <feGaussianBlur stdDeviation="1.5" result="blur"/>
		      <feFlood flood-opacity="0.039"/>
		      <feComposite operator="in" in2="blur"/>
		      <feComposite in="SourceGraphic"/>
		    </filter>
		  </defs>
		  <g transform="matrix(1, 0, 0, 1, 0, 0)" filter="url(#Rectangle-6)">
		    <path id="Rectangle-6-2" data-name="Rectangle-6" d="M63,0H477V375.216L63,482.891Z" transform="translate(-58.5 3.5)" fill="#f4eded" fill-rule="evenodd" opacity="0.445"/>
		  </g>
		</svg>
	</div>
	<div v-else class="background">
		<svg xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" width="1375" height="635.206" viewBox="0 0 1375 635.206">
		  <defs>
		    <filter id="Rectangle-6" x="0" y="0" width="1375" height="635.206" filterUnits="userSpaceOnUse">
		      <feOffset dy="1" input="SourceAlpha"/>
		      <feGaussianBlur stdDeviation="1.5" result="blur"/>
		      <feFlood flood-opacity="0.039"/>
		      <feComposite operator="in" in2="blur"/>
		      <feComposite in="SourceGraphic"/>
		    </filter>
		  </defs>
		  <g transform="matrix(1, 0, 0, 1, 0, 0)" filter="url(#Rectangle-6)">
		    <path id="Rectangle-6-2" data-name="Rectangle-6" d="M63,0H1429V486.575L63,626.206Z" transform="translate(-58.5 3.5)" fill="#f4eded" fill-rule="evenodd" opacity="0.445"/>
		  </g>
		</svg>
	</div>
</template>

<script>
	export default {
		props: ['mobile'],
	}
</script>