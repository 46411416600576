<template>
	<div class='auth-header-mini'>
		<div class='auth-navbar d-flex justify-content-between'>
			<div class='left-content'>
				<img src="../../assets/img/icons/bigcommand-icon.svg">
			</div>
			<div class='right-content'>
				<div class='user-options d-flex' @mouseleave='triggerMenu(false)'>
					<div class='user-details' v-if='user'>
						<div class='user-name'>{{ user.name }}</div>
						<div class='user-company'>{{ user.settings.company }}</div>
					</div>
					<div class='user-profile' @mouseover='triggerMenu(true)'>
						<img v-if='!user.settings.avatar.includes("gravatar")' :src='user.settings.avatar' alt='avatar' class='img-circle row-space-top-2' height='40' width='40'>
						<img v-else src='../../assets/img/icons/profile-pic-default.svg' alt='avatar' class='img-circle'>
						<img class='arrow-down' src='../../assets/img/icons/arrow-down-light.svg' alt='arrow'>
					</div>
					<div class="profile-menu" v-show='showMenu'>
						<div class="profile-menu-user-details">
							<div class="profile-menu-user-avatar">
								<img v-if='!user.settings.avatar.includes("gravatar")' :src='user.settings.avatar' alt='avatar' class='img-circle row-space-top-2' height='60' width='60'>
								<img v-else src='../../assets/img/icons/profile-pic-default.svg' alt='avatar' class='img-circle'>
							</div>
							<div class="profile-menu-user-name">
								{{ user.name }}
							</div>
						</div>
						<router-link to="/logout">
                            <span>Logout</span>
                        </router-link>
					</div>
				</div>
			</div>
		</div>
	</div>
</template>

<style lang='less'>
	.auth-header-mini {
		.auth-navbar {
			width: 785.83px;
			height: 77.65px;
			background: #EDF4F4;
			.left-content {
				height: max-content;
				margin: 15px 30px;
			}
			.right-content {
				height: max-content;
				margin: 15px 30px;
				.user-options {
					position: relative;
					.user-details {
						margin: 0 5px 0 0;
						text-align: right;
						display: flex;
						align-self: center;
						flex-direction: column;
						.user-name {
							font-size: 13px;
						}
						.user-company {
							font-size: 11px;
							color: #A3BAC6;
						}
					}
					.user-profile {
						cursor: pointer;
						.arrow-down {
							margin-left: 5px;
						}
					}
					.profile-menu {
					    position: absolute;
					    right: 0;
					    top: 45px;
					    background: #fff;
					    z-index: 100;
					    border: none;
					    border-radius: 2px;
					    -webkit-box-shadow: 0 2px 12px 0 rgba(0,0,0,.1);
					    box-shadow: 0 2px 12px 0 rgba(0,0,0,.1);
					    width: 200px;
					    color: #909399;
					    transition: 0.2s;
					    overflow: hidden;
					    .profile-menu-user-details {
						    display: flex;
						    flex-direction: column;
						    align-items: center;
						    .profile-menu-user-avatar {
						    	padding: 15px;
						    }
						    .profile-menu-user-name {
						    	padding: 0 0 15px 0;
						    }
					    }
					    a {
					    	text-decoration: none;
					    	color: #909399;
					    }
					}
				}
			}
		}
	}
	@media (max-width: 960px) {
		.auth-header-mini {
			.auth-navbar {
				width: 100% !important;
				.right-content {
					margin: 15px !important;
					.user-options {
						.user-details {
							display: none;
						}
					}
				}
			}
		}
	}
</style>

<script>
	export default {
		name: 'AuthHeaderMini',
		props: ['user', 'disable'],
		data: () => ({
			showMenu: false,
		}),
		methods: {
			triggerMenu(trigger) {
				const disabled = this.$props.disable;
				if (disabled) return false;
				let show = this.showMenu;
				if (!show) {
					const menu = document.querySelector('.profile-menu');
					if (menu) {
						menu.style.height = '0px';
						menu.style.padding = '0px';
						setTimeout(() => {
							menu.style.height = '165px';
							menu.style.padding = '10px 15px';
						}, 100);
					}
				}
				this.showMenu = trigger;
			}
		}
	}
</script>