<template>
	<div class='payment-box-container' v-if='!bigLoading'>
		<div class="payment-box">
			<auth-header v-if='user' :user="user" :disable='loading || bigLoading'></auth-header>
			<div class="payment-box-content">
				<div class="payment-box-headers">
					<div class="payment-box-title">Final step, activate your account</div>
					<div class="payment-box-desc">Enter your credit card to activate your account, you won’t be billed till your free trial expires and you can cancel anytime</div>
				</div>
				<div class="error-container">
					<div class="error-messages" v-if="getError('card')">
						<div>{{ getError('card') }}</div>
					</div>
				</div>
				<div class="payment-box-form">
					<label for="card-input">Card Number</label>
					<div class="card-input-type">
						<input :disabled='loading' v-model="paymentDetails.card" class="form-control card-input" placeholder="Enter Card Number" id="card-input" @input='formatInputs($event, "card")' />
						<SubCardType :cardType='cardType'/>
					</div>
					<div class="card-details d-flex justify-content-between">
						<div class="card-expiry">
							<div class='flex-column'>
								<label for="card-input">Expiration Date</label>
								<div class="d-flex">
									<div class="relative-position h-44">
										<select :disabled='loading' name="months" id="month" class="form-control card-month text-center" v-model="paymentDetails.month" @change='validate(liveValidation)'>
											<option :selected='paymentDetails.month === month ? "true" : "false"' :value="month" v-for='(month, index) in months' :key='index'>
												{{ month }}
											</option>
										</select>
										<img src='../../assets/img/icons/arrow-down-light.svg' alt='arrow' class='select-arrow'>
									</div>
									<div class="card-input-divider">
										<svg xmlns="http://www.w3.org/2000/svg" width="11.182" height="1" viewBox="0 0 11.182 1">
				  							<line id="Line_356" data-name="Line 356" x2="10.182" transform="translate(0.5 0.5)" fill="none" stroke="#21455e" stroke-linecap="round" stroke-width="1"/>
										</svg>
									</div>
									<div class="relative-position h-44">
										<select :disabled='loading' name="year" id="year" class="card-year form-control" v-model="paymentDetails.year" @change='validate(liveValidation)'>
											<option :value="year" v-for='(year, index) in years' :key='index' :selected='paymentDetails.year === year ? "true" : "false"'>
												{{ year }}
											</option>
										</select>
										<img src='../../assets/img/icons/arrow-down-light.svg' alt='arrow' class='select-arrow'>
									</div>
								</div>
								<span class="error-text">{{ getError('date') }}</span>
							</div>
						</div>
						<div class="card-cvv-input">
							<div class="flex-column">
								<label for="card-input">CVV</label>
								<input :disabled='loading' class="card-cvv form-control" placeholder="CVV" v-model="paymentDetails.cvv" @input='formatInputs($event, "cvv")'>
								<span class="error-text">{{ getError('cvv') }}</span>
							</div>
						</div>
					</div>
					<div class="card-lower-details">
						<div class="card-coupon" v-if='!showCoupon'>
							<i>Do you have a coupon code?<a href="#" @click='showCouponInput'>Click here</a></i>
						</div>
						<div class="coupon-code" v-else>
							<label>Coupon Code</label>
							<div class="coupon-input-container">
								<input :disabled='loading' class="card-coupon form-control" placeholder="Enter Coupon Code" v-model="paymentDetails.coupon" @change='applyCoupon' :class='couponClass'>
								<img src="../../assets/img/green-check.svg" class='green-check' v-if='appliedCoupon'>
								<div class="coupon-response-message">
									<i>{{ couponMessage }}</i>
								</div>
							</div>
						</div>
						<!-- discount_type -->
						<div v-if='selectedPlan'>
							<div class="card-plan-details" v-if='!appliedCoupon'>
									Your fees: 
									<span v-if='selectedPlan.trial'><b>$0.00 for {{ selectedPlan.trial }} days</b> then </span>
									${{ selectedPlan.amount }}/{{ selectedPlan.interval }} ({{ selectedPlan.name }}).
							</div>
							<div v-else class="card-plan-details">
								Your fees: 
								<span v-if='selectedPlan.trial'>
									<b>$0.00 for {{ selectedPlan.trial }} days </b>
									and
								</span>
								<span v-if='appliedCoupon.discount_type === "percent"'>
									${{ selectedPlan.amount * appliedCoupon.amount / 100 }}
								</span>
								<span v-if='appliedCoupon.discount_type === "flat"'>
									${{ selectedPlan.amount - appliedCoupon.amount }}
								</span>
								on your first {{ selectedPlan.interval }} subscription then
								${{ selectedPlan.amount }}/{{ selectedPlan.interval }}
								({{ selectedPlan.name }})
							</div>
						</div>
					</div>
					<button :disabled='liveValidation && !validated' class="card-submit-button" @click='submit' v-if='!loading'>
						Activate My Account
					</button>
					<div class="card-submit-button" v-else>
						<base-loader :showLoader='loading' :centered='true' :lightTheme='true' />
					</div>
				</div>
				<div class="checkout-footer">
					<div class="footer-content">© 2020 All Rights Reserved. Bigcommand LLC. </div>
					<div class="footer-content footer-links footer-content-right">
						<a href="">Terms</a>
						<a href="">Privacy</a>
						<a href="">Cancellation</a>
						<a href="">Disclaimer</a>
						<a href="">Cookies</a>
					</div>
				</div>
			</div>
		</div>
		<base-alert
			:show='alert.show'
			:message='alert.message'
			:theme='alert.theme' />
	</div>
</template>

<style lang="less" scoped>
	@import '../../assets/less/custom/fonts.less';
	.payment-box-container {
		background: #FDF9EF;
		position: absolute;
		top: 0;
	    width: 100vw;
    	height: 100%;
    	overflow: auto;
	}
	.relative-position {
		position: relative;
	}
	.h-44 {
		height: 44px;
	}
	.select-arrow {
		position: absolute;
		right: 15px;
		top: 20px;
	}
	.payment-box {
		width: max-content;
		min-height: 740px;
	    position: absolute;
	    left: 0;
	    right: 0;
	    top: 100px;
		margin: auto;
		padding: 0 0 20px 0;
		background: #fff;
		.payment-box-content {
			position: relative;
			left: 0;
			right: 0;
			margin: 50px auto auto auto;
			width: max-content;
		}
		.coupon-input-container {
			position: relative;
			.green-check {
				position: absolute;
				top: 12px;
				right: 10px;
			}
		}
		.success-border {
			border-color: #BFE0C8 !important;
		}
		.fail-border {
			border-color: #F05E44 !important;
		}
		select {
			-moz-appearance: none;
			appearance: none;
			text-align: center;
		}
	    .error-text {
	    	color: #F05E44;
	    }
	    .error-container {
	    	margin-top: 25px;
	    	.error-messages {
	    		background: #FAE09D;
	    		color: #F05E44;
	    		height: 29px;
	    		width: 464.35px;
	    		position: relative;
	    		left: 0;
	    		right: 0;
	    		margin: auto;
	    		border-radius: 12px;
	    		padding: 3px 15px;
	    		font-size: 16px;
	    	}
	    }
	    .payment-box-headers {
	    	width: 596px;
	    	height: 98px;
	    	text-align: center;
    	    position: relative;
		    left: 0;
		    margin: auto;
	    	.payment-box-title {
	    		font-family: "Poppins-Medium";
	    		font-size: 30px;
	    	}
	    	.payment-box-desc {
	    		font-family: "Poppins-Light";
	    		font-size: 18px;	
	    	}
	    }
	    .payment-box-form {
	    	width: 453px;
	    	position: relative;
			top: 30px;
			left: 0;
			margin: auto;
			.coupon-response-message {
				font-size: 17px;
			}
		    label {
		    	font-size: 16px;
		    	font-family: "Poppins-Light";
		    	padding-bottom: 10px;
		    }
		    input::placeholder {
		    	color: #98A4B3;
		    }
	    	.card-input-type {
	    		margin-bottom: 25px;
	    	}
	    	.card-expiry {
	    		display: flex;
				input,
				select {
	    			width: 103.27px;
	    		}
	    		.card-month {
	    			border-top-left-radius: 5px;
	    			border-bottom-left-radius: 5px;
	    		}
	    		.card-input-divider {
	    			width: 23.14px;
	    			background: #F5F6F9;
	    			position: relative;
	    			svg {
	    				position: absolute;
					    top: 0;
					    bottom: 0;
					    left: 0;
					    right: 0;
					    margin: auto;
	    			}
	    		}
	    		.card-year {
	    			border-top-right-radius: 5px;
	    			border-bottom-right-radius: 5px;
	    		}
	    		.card-cvv {
	    			width: 135.15px;
	    		}
	    	}
	    	.card-lower-details {
	    		font-size: 17px;
	    		padding: 20px 0 0 0;
	    		a {
	    			margin-left: 5px;
	    			text-decoration: underline;
	    			color: #21455E;
	    			font-weight: bold;
	    		}
	    		.card-plan-details {
	    			color: #D87520;
	    			padding: 20px 0;
	    		}
	    	}
	    	.card-submit-button {
	    		background: #0AAF1D;
	    		color: #fff;
	    		text-align: center;
	    		width: 100%;
	    		height: 60.18px;
    		    position: relative;
			    left: 0;
			    right: 0;
			    margin: auto;
			    font-family: "Poppins-Medium";
			    font-size: 25px;
			    padding: 10px 0;
			    border-radius: 30px;
			    cursor: pointer;
				padding-top: 15px;
				position: relative;
				z-index: 5;
				border: none;
	    	}
	    }
	}
    .checkout-footer {
 	   	display: flex;
        justify-content: space-between;
        padding: 70px 5px 10px 15px;
		text-align: center;
        color: #A3BAC6;
		font-size: 14px;
		position: relative;
		left: 0;
		right: 0;
		margin: auto;
		.footer-content-right {
			margin-left: 50px;
		}
        a {
            text-decoration: none;
            color: #A3BAC6;
            margin: 0 5px;
        }
	}
	
	@media (max-width: 785px) {
		.payment-box-container {
			overflow: auto !important;
			background: #fff;
		}
		.payment-box-content {
			width: 100% !important;
		}
		.payment-box {
			width: 100%;
			position: absolute;
		    top: 0;
		    bottom: auto;
		    margin: auto;
			.payment-box-content {
				padding: 0 10px;
			}
			.payment-box-headers {
				width: 100%;
				.payment-box-title {
					font-size: 20px;
				}
				.payment-box-desc {
					font-size: 13px;	
				}
			}
			.payment-box-form {
				width: 100%;
				left: 0;
				.form-control card-input {
					width: 100%;
				}
				.coupon-response-message {
					font-size: 13px;
				}
			}
			.error-container {
	    		.error-messages {
					width: 100% !important;
					font-size: 13px !important;
					height: max-content;
				}
			}
			.card-coupon,
			.card-plan-details,
			label {
				font-size: 13px !important;
			}
			.card-submit-button {
				font-size: 18px !important;
				width: 100% !important;
				padding: 10px !important;
				height: 45px !important;
			}
		}
		.checkout-footer {
			flex-direction: column;
			width: 100%;
			text-align: center;
			a {
				margin: 0px !important; 
				padding: 0 5px;
			}
			.footer-content-right {
				margin-left: 0px !important;
			}
		}
	}
</style>

<script>

	import AuthHeader from "./AuthHeaderMini";
	import moment from 'moment';
	import SubCardType from './Subscription/SubCardType';
	import { mapMutations, mapState, mapActions } from "vuex";

	export default {
		name: 'SubscriptionCheckout',
		components: {
			AuthHeader,
			SubCardType
		},
		data: () => ({
			showCoupon: false,
			selectedPlan: [],
			paymentDetails: {
				card: '',
				month: '01',
				year: 2020,
				cvv: '',
				coupon: '',
			},
			years: [],
			months: [],
			cardType: '',
			errorMessages: [],
			validated: false,
			loading: false,
			bigLoading: true,
			liveValidation: false,
			couponClass: '',
			appliedCoupon: false,
			couponMessage: '',
			alert: {
				show: false,
				message: '',
				theme: '',
			},
		}),
		mounted() {
			this.ifNoPlan();
			this.checkAuthorisation();
			this.getYears();
			this.getMonths();
		},
		methods: {
			checkAuthorisation() {
				this.bigLoading = true;
				const planId = this.$route.query.plan;
				this.axios.get(`payments/authorisation-check?plan_id=${planId}`)
					.then(response => {
						const canBuyPlan = response.data.success;
						const selectedPlan = response.data.plan;

						this.bigLoading = false;
						this.selectedPlan = selectedPlan;

						if (!canBuyPlan || !selectedPlan) {
							this.$router.push('/');
						} else {
							this.bigLoading = false;
						}
					}).catch(error => {
						const details = error.response.data;
						let message = 'Server error, please try again.';
						this.bigLoading = false;
						
						if (error.response.status !== 404 && details.message) {
							message = details.message;
						}
						setTimeout(() => {
							this.$router.push('/subscription');
						}, 3000);
						this.alertTrigger('error-theme', message);
					});
			},
			showCouponInput(e) {
				this.showCoupon = true;
				e.preventDefault();
			},
			ifNoPlan() {
				const plan = this.$route.query.plan;
				if (!plan) return this.$router.push('/');
			},
			getYears() {
				const startYear = moment().year();
				const endYear   = startYear + 10;
				for (let i = startYear; i < endYear; i++) {
					this.years.push(i);
				}
			},
			getMonths() {
				let num = 1;
				for (let i = num; i <= 12; i++) {
					const length = i.toString().length;
					if (length === 1) {
						num = `0${i.toString()}`
					} else {
						num = i.toString();
					}
					this.months.push(num);
				}
			},
			getCardType() {
				let number = this.paymentDetails.card
					.split(" ")
					.filter((s) => s)
					.join("");

				this.cardType = "";
				// visa
				let re = new RegExp("^4");
				if (number.match(re) != null) {
					this.cardType = "Visa";
				}

				// Mastercard
				// Updated for Mastercard 2017 BINs expansion
				if (
					/^(5[1-5][0-9]{14}|2(22[1-9][0-9]{12}|2[3-9][0-9]{13}|[3-6][0-9]{14}|7[0-1][0-9]{13}|720[0-9]{12}))$/.test(
					number
					)
				) {
					this.cardType = "Mastercard";
				}

				// AMEX
				re = new RegExp("^3[47]");
				if (number.match(re) != null) {
					this.cardType = "AMEX";
				}

				// Discover
				re = new RegExp(
					"^(6011|622(12[6-9]|1[3-9][0-9]|[2-8][0-9]{2}|9[0-1][0-9]|92[0-5]|64[4-9])|65)"
				);
				if (number.match(re) != null) {
					this.cardType = "Discover";
				}

				// Diners
				re = new RegExp("^36");
				if (number.match(re) != null) {
					this.cardType = "Diners";
				}

				// Diners - Carte Blanche
				re = new RegExp("^30[0-5]|38[0-5]");
				if (number.match(re) != null) {
					this.cardType = "Diners - Carte Blanche";
				}

				// JCB
				re = new RegExp("^35(2[89]|[3-8][0-9])");
				if (number.match(re) != null) {
					this.cardType = "JCB";
				}

				// Visa Electron
				re = new RegExp("^(4026|417500|4508|4844|491(3|7))");
				if (number.match(re) != null) {
					this.cardType = "Visa Electron";
				}
			},
			submit() {
				this.validate();
				const subscription = this.getSubscripion();
				subscription.card_brand = this.cardType;
				return;
				if (this.validated) {
					this.loading = true;
					this.axios.post('payments/start-trial', { subscription })
						.then(response => {
							console.log(response);
							this.loading = false;
						})
						.catch(error => {
							this.alertTrigger('error-theme', 'Server error, please try again.');
							console.log(error.response);
							setTimeout(() => {
								// this.$router.push('/subscription');
							}, 3000);
							this.loading = false;
						});
				}
			},
			fbTracking() {
				const { rate, fbq_label } = this.selectedPlan;

				fbq('track', 'Purchase', {
					content_name: fbq_label,
					content_type: 'subscription',
					value: 0,
					currency: 'USD',
				});
				fbq('track', 'Subscribe', {
					content_name: fbq_label,
					content_type: 'subscription',
					value: rate,
					currency: 'USD',
				});
				fbq('trackCustom', 'StartTrial', {
					content_name: '4KPlayer Basic Trial Plan',
					content_type: 'subscription',
					value: rate,
					currency: 'USD',
				});
			},
			getSubscripion() {
				const currentPlan = this.selectedPlan;
				const coupon = this.appliedCoupon;
				let { card, cvv, month, year } = this.paymentDetails;

				card = card.split(" ").join("");
				const subscriptionObject = {
					card_number: card,
					cvc: cvv,
					plan: currentPlan.label,
					plan_id: currentPlan.id,
					plan_cost: currentPlan.rate,
					plan_duration: currentPlan.duration,
					exp_month: month,
					exp_year: year,
					user_email: "",
					user_name: "",
					user_id: "",
					invoice_id: "",
					discount: null,
					details:[],
					bundle: false,
					discount_price: 0,
					payment_method: '',
				}

				return subscriptionObject;
			},
			validate(check = true) {
				if (!check) return false;
				let { card, month, year, coupon, cvv } = this.paymentDetails;
				const currentMonth = moment().month() + 1;
				const currentYear  = moment().year();
				let errors = [];
				card = card.split(" ").join("");
				this.liveValidation = true;
				if (!card || !this.cardType || card.length < 16) errors.push({ card: 'Please enter a valid card number' });
				if (year === currentYear && month <= currentMonth) errors.push({ date: 'Invalid expiry date' });
				if (!cvv || cvv.length < 3) errors.push({ cvv: 'Please enter a valid cvv number' });

				this.errorMessages = errors;
				if (!errors.length) this.validated = true;
				else this.validated = false;
			},
			getError(type) {
				const error = this.errorMessages.filter( error => error[type] );
				const cardError = type === 'card' ? 'Card Error: ' : '';
				return error.length ? cardError + error[0][type] : '';
			},
			formatInputs(e, type) {
				let value = e.target.value;
				if (this.liveValidation) this.validate();
				switch (type) {
					case 'card':
						this.getCardType();

						for (let i = 0; i < value.length; i++) {
							let position = i + 1;
							let indexVal = value[i];
							if (position % 5 === 0) {
								if (indexVal != " ") {
									this.paymentDetails.card = value.slice(0, i) + ' ' + value.slice(i);
								}
							} else {
								if (indexVal === ' ') {
									this.paymentDetails.card = this.paymentDetails.card.slice(0, i);
								}
							}
							if (indexVal != ' ' && isNaN(indexVal)) {
								this.paymentDetails.card = this.paymentDetails.card.slice(0, i);
							}
						}
						if (value != '' && value.replace(/\D/g, "").length > 16) {
							this.paymentDetails.card = this.paymentDetails.card.slice(0, 19);
						}
					break;
					case 'cvv':
						if (value && isNaN(value)) {
							this.paymentDetails.cvv = value.replace(/\D/g, "");
						}
						if (value.length > 4) {
							this.paymentDetails.cvv = value.slice(0, 4);
						}
					break;
				}
			},
			...mapMutations({
				setUserInfo: "setUserInfo",
			}),
			applyCoupon() {
				const code = this.paymentDetails.coupon;
				this.loading = true;
				this.axios.get('payments/coupon', { params: { code } })
					.then(response => {
						this.loading = false;
						const couponData = response.data.coupon;
						const couponMessage = response.data.message;

						if (couponData) {
							if (couponData) {
								this.appliedCoupon = couponData;
								this.couponClass = 'success-border';
							} else {
								this.appliedCoupon = false;
							}
						} else {
							this.appliedCoupon = false;
							this.couponClass = 'fail-border';
						}
						this.couponMessage = couponMessage;
					}).catch(error => {
						this.loading = false;
						console.log(error);
					});
			},
			alertTrigger(theme, message = false) {
				this.alert.show = message ? true : message;
				this.alert.message = message ? message : '';
				this.alert.theme = theme;
			},
		},
		computed: {
	        user: {
	            get() {
	                return this.$store.state.userData;
	            },
        	},
		},
	}
</script>