<template>
    <div>
        <span class="card-icon blank" v-if="cardType==''">
            <img
            alt="Card"
            class="img-responsive card-mark"
            src="../../../assets/img/auth/card/blank.svg"
            />
        </span>
        <span class="card-icon visa" v-if="cardType=='Visa'">
            <img
            alt="Visa"
            class="img-responsive card-mark"
            src="../../../assets/img/auth/visa_2x.svg"
            />
        </span>
        <span class="card-icon master" v-if="cardType=='Mastercard'">
            <img
            alt="Master"
            class="img-responsive card-mark"
            src="../../../assets/img/auth/card/mastercard.svg"
            />
        </span>
        <span class="card-icon amex" v-if="cardType=='AMEX'">
            <img
            alt="Master"
            class="img-responsive card-mark"
            src="../../../assets/img/auth/card/amex.svg"
            />
        </span>
        <span class="card-icon discover" v-if="cardType=='Discover'">
            <img
            alt="Master"
            class="img-responsive card-mark"
            src="../../../assets/img/auth/card/discover.svg"
            />
        </span>
        <span
            class="card-icon diner"
            v-if="cardType=='Diners - Carte Blanche' || cardType=='Diners'"
        >
            <img
            alt="Diner"
            class="img-responsive card-mark"
            src="../../../assets/img/auth/card/diners.svg"
            />
        </span>

        <span class="card-icon jcb" v-if="cardType=='JCB'">
            <img
            alt="Diner"
            class="img-responsive card-mark"
            src="../../../assets/img/auth/card/jcb.svg"
            />
        </span>
    </div>
</template>

<script>
export default {
    name: 'SubCardType',
    props: ['cardType']
}
</script>

<style lang="less" scoped>
    .card-icon {
        width: 36px;
        position: absolute;
    }
    .card-icon.master {
        width: 41px;
        top: 43px;
        right: 17px;
    }
    .card-icon.discover {
        width: 50px;
        top: 43px;
        right: 17px;
    }
    .card-icon.jcb {
        width: 50px;
        top: 40px;
        right: 0px;
    }
    .card-icon.blank {
        width: 32px;
        top: 40px;
        right: 10px;
    }
    .card-icon.amex {
        width: 45px;
        top: 43px;
        right: 11px;
    }
    .card-icon.visa {
        width: 32px;
        top: 52px;
        right: 12px;
    }
    .card-icon.diner {
        width: 42px;
        top: 44px;
        right: 0px;
    }
</style>