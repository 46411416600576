<template>
    <div class="topbar" id="top-bar">
        <div id="main_menu">
            <el-menu :router="true" class="el-menu-main" mode="horizontal">
                <div class="left">
                    <el-menu-item :key="1">
                        <img
                            alt="Account"
                            class="logo"
                            src="../../assets/img/auth/subscription-header-logo.svg"
                        />
                    </el-menu-item>
                </div>
                <div class="right">
                    <el-submenu class="profile-menu-item" index="">
                        <template slot="title">
                            <div class="user-info">
                                <div class="user-name">{{user.full_name}}</div>
                                <div class="user-company text-capitalize">{{user.settings.company}}</div>
                            </div>
                            <img :src="user.settings.avatar"
                                 alt=""
                                 class="img-circle profile-avatar"
                                 v-if="user.settings"
                                 width="42">
                        </template>

                        <el-menu-item class="text-center" index="settings" style="height: auto">
                            <img :src="croppa(user.settings.avatar, 150, 150)" alt="" class="img-circle row-space-top-2" height="60" width="60">
                            <div class="user-identifire">
                                {{ user.full_name }}
                            </div>
                        </el-menu-item>

                        <router-link to="/logout">
                            <el-menu-item index="logout">Logout</el-menu-item>
                        </router-link>
                    </el-submenu>
                </div>
            </el-menu>

            <div class="hamb"></div>
        </div>
    </div>
</template>

<script>
    export default {
        name: "AuthHeader",
        props: ["user"],
    };
</script>

<style>
    .el-menu--popup {
        z-index: 100;
        border: none;
        padding: 5px 0;
        border-radius: 2px;
        -webkit-box-shadow: 0 2px 12px 0 rgba(0,0,0,.1);
        box-shadow: 0 2px 12px 0 rgba(0,0,0,.1);
    }
    .el-menu--popup-bottom-start {
        margin-top: 5px;
    }
    .el-menu {
        border-right: solid 1px #e6e6e6;
        list-style: none;
        position: relative;
        margin: 0;
        padding-left: 0;
        background-color: #FFF;
    }
    .el-menu--horizontal .el-menu .el-menu-item, .el-menu--horizontal .el-menu .el-submenu__title {
        background-color: #FFF;
        float: none;
        height: 36px;
        line-height: 36px;
        padding: 0 10px;
        color: #909399;
    }
    .el-menu-item * {
        vertical-align: middle;
    }
    .el-menu--horizontal {
        border-right: none;
    }
    .el-menu-item:last-child {
        margin-right: 0;
    }
    .el-menu-item:first-child {
        margin-left: 0;
    }
    .el-menu--horizontal .el-menu-item {
        float: left;
        height: 60px;
        line-height: 60px;
        margin: 0;
        cursor: pointer;
        position: relative;
        box-sizing: border-box;
        border-bottom: 5px solid transparent;
    }
    .el-menu--collapse .el-menu .el-submenu, .el-menu--popup {
        min-width: 200px;
    }
    .el-menu--popup-bottom-start {
        margin-top: 5px;
    }
</style>

<style lang="less" src="../../assets/less/custom/app-header.less"></style>
